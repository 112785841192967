<template>
  <div class="columns">
    <div class="column is-6 is-offset-3">
      <div v-if="error">
        <div class="notification is-danger">
          {{ error }}
        </div>
      </div>
      <div v-if="loading" class="text-center">
        <h2>Cargando...</h2>
      </div>
      <div class="simulator" v-if="!loading && ready">
        <simulatorTerms :values="values"></simulatorTerms>
        <div v-if="values.amount > 0">
          <simulatorSlider :values="values" :step="50000"></simulatorSlider>
        </div>
        <div class="SimulatorResults" v-if="computed.firstDatePayment">
          <simulatorResult label="Total desembolso" :value="computed.totalDisbursed" :format="true"></simulatorResult>
          <simulatorResult label="Cuota mensual" :value="computed.monthlyQuote" :format="true"></simulatorResult>
          <simulatorResult label="Primera fecha de pago" :value="computed.firstDatePayment" :format="false">
          </simulatorResult>
        </div>
        <div class="loanPurpose">
          <label>Propósito del préstamo</label><br />
          <div class="select is-fullwidth">
            <select v-model="porpose">
              <option value="">Seleccione una opción</option>
              <option v-for="item in getLoanPurposeSelecters" :key="item.value" :value="item.value">
                {{ item.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="columns mt-4">
          <div class="column is-12">
            <div class="buttons">
              <button @click="sendLoans" class="button is-primary is-medium is-fullwidth">
                SOLICITAR CRÉDITO
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useSimRiskMatriz from "./useSimRiskMatriz"
import { riskLevelMatrix, buroGet } from "@/services/validation"
import validatePermissions from "../../helpers/validatePermissions"
import { required } from "vuelidate/lib/validators"
import { mapGetters, mapActions, mapMutations } from "vuex"
// componentes
import simulatorTerms from "./simulatorTerms"
import simulatorSlider from "./simulatorSlider"
import simulatorResult from "./simulatorResult"

export default {
  data() {
    return {
      porpose: "",
      error: "",
      loading: false
    }
  },
  validations: {
    porpuse: {
      required
    }
  },
  methods: {
    ...mapMutations({
      completeFormStep: "user/completeFormStep",
      addLoanActive: "user/addLoanActive"
    }),
    ...mapActions({
      getClient: "user/getClient",
      patchLoans: "user/patchLoans",
      cleanData: "user/cleanData",
    }),
    async sendLoans() {
      this.error = ""
      this.loading = true

      try {
        let loanId = this.getloanActive
        if (loanId === undefined) {
          throw new Error("Error en el estado de credito.")
        }

        if (!this.porpose) {
          throw new Error("Debe seleccionar el propósito del prestamo")
        }

        let payload = {
          amount: parseInt(this.values.amount, 10),
          repaymentsStartingFromDate: this.formarFirstDatePayment,
          term: this.values.term,
          loanPurpose: this.porpose,
          termsAndConditions: true
        }
        await this.patchLoans({
          loanId: loanId.id,
          documentNumber:this.getDocumentNumber,
          payload
        })
        await this.getClient({
          value: this.getDocumentNumber,
          method: "id"
        })
        await this.completeFormStep("simulator")
        this.loading = false
      } catch (e) {
        this.loading = false
        this.error = e.message
      }
    }
  },
  async beforeMount() {

    validatePermissions("MON_PLA", "No tiene permisos para gestionar a un cliente en la pantalla de Monto y Plazo");

    try {
      let documentNumber = this.getDocumentNumber
      let loanId = this.getLoadSubmittedAndAwaitingRequest
      let loanActive = this.getloanActive

      if (loanActive) {
        loanId = loanActive
      }

      if (loanId === undefined) {
        let loans = this.getLoans
        let status = loans.map(loan => loan.status.value)

        if (
          status.find(value => value === "Submitted and awaiting scrapping")
        ) {
          throw new Error(
            "No logramos verificar tus ingresos, envíanos tus extractos bancarios de los últimos 3 meses a ayuda@juanchotepresta.com, o contáctanos a 018000413811"
          )
        } else {
          throw new Error("Error en el estado de credito. [" + status + "]")
        }
      }

      this.addLoanActive(loanId)
      let { genderId } = this.getPersonalInformation
      let { label } = this.getGender(genderId)
      this.values.gender = label

      let { monthlySalary, disposableIncome } = this.getLaboralInformation

      let riskMatrix = await riskLevelMatrix()
      let resp_buro = await buroGet(documentNumber, loanId.id)
      let { risk, finalPath, incomeFactor, mark, maxRiskQuota } = resp_buro.data
      if (!risk) throw new Error("Nivel de riesgo indefinido")
      if (!riskMatrix[risk])
        throw new Error(
          `Nivel de riesgo no encontrado en la matriz de riesgo [${risk}]`
        )

      if (!riskMatrix[risk][finalPath])
        throw new Error(`Camino no encontrado [${finalPath}]`)

      riskMatrix[risk][finalPath]['maxAmount'] =
        mark !== 'NA' ? maxRiskQuota : riskMatrix[risk][finalPath]['maxAmount'];

      this.setParams({
        income: monthlySalary,
        riskMatrix: riskMatrix[risk][finalPath],
        incomeFactor,
        disposableIncome
      })
    } catch (e) {
      this.error = e.message
    }
  },
  computed: {
    ...mapGetters({
      getLoanPurposeSelecters: "user/getLoanPurposeSelecters",
      getDocumentNumber: "user/getDocumentNumber",
      getPersonalInformation: "user/getPersonalInformation",
      getLaboralInformation: "user/getLaboralInformation",
      getGender: "user/getGender",
      getLoadSubmittedAndAwaitingRequest:
        "user/getLoadSubmittedAndAwaitingRequest",
      getLoans: "user/getLoans",
      getloanActive: "user/getloanActive"
    })
  },
  mixins: [useSimRiskMatriz],
  components: {
    simulatorTerms,
    simulatorSlider,
    simulatorResult
  }
}
</script>
<style scoped>
.SimulatorResults {
  text-align: left;
  margin: 24px 0 16px;
}

.loanPurpose {
  margin-top: 10px;
}

.loanPurpose>label {
  font-weight: 700;
}
</style>
<style>
.title {
  color: #083863;
}
</style>
